<template>
    <q-dialog 
      ref="dialogVEx"
      v-model="modalForm.show"
      content-class="cannaFormModal"
      transition-show="scale" 
      transition-hide="scale"
      :persistent="(is_Mobile || $q.screen.lt.sm)?true:false"
      :full-width="(is_Mobile || $q.screen.lt.sm)?true:false"
      :full-height="(is_Mobile|| $q.screen.lt.sm)?true:false"
      :no-esc-dismiss="(is_Mobile|| $q.screen.lt.sm)?false: ((modalForm.processing) ? true : false)"
      :no-backdrop-dismiss="(is_Mobile|| $q.screen.lt.sm)?false:true"
      @show="setShownDialog"
      @escape-key="doCloseDialog(true)"
    >
      <q-card class="canna_Formx canna_FormxAviso" style="overflow: hidden;">
        <!-- encabezado -->
        <q-card-section horizontal class="q-card q-pa-md flex justify-between column noselect">
          <div class="closeButton">
            <cxIcon
              iconType="ui_close"
              size="24px"
              class=""
              :cls="'icon_close_svg'"
              :state="(modalForm.processing === false) ? 0 : 2"
              :hasMouseOver="true"
              :hasAction="true"
              :allowDisabled="true"
              :allowClick="true"
              @iconClick="doCloseDialog"
            />
          </div>
          <div class="form_title">

            <span>Aviso de privacidad</span>
            <!-- 
            <div class="subtitleAlt subtitleError">
              Hay elementos que necesitan tu atención
            </div>
            -->

          </div>
        </q-card-section>

        <q-separator />
        <!-- formulario -->

          <q-card-section  class="canna_FormxContents scroll"
            style="position: relative;padding: 16px 18px;display: flex;flex-direction: column;"
            :style="(is_Mobile|| $q.screen.lt.sm) ? 'max-height:88%' : 'max-height: calc(85vh - 120px);' "
          >
            <!-- Contenido del formulario -->
            <div class="canna_forma_zona spacerRowTop24" 
              style="margin-top: 0px !important; flex: 1 1 0%; overflow: auto;"
              :style="(is_Mobile|| $q.screen.lt.sm) ? '' : 'max-height: calc(85vh - 280px);' "
            >

              <div id="avisoDoc" style="font-size: 13px; height: 100%; overflow: auto; width: 100% !important;color: #575961;">

                  <div style="font-size: 19px;margin-bottom: 16px; display:none;" class="avisoTitle" >
                    <span class="ap_highlightAlt">AVISO DE PRIVACIDAD</span>
                  </div>

                  <p>
                    Advertising and Promotion, S.A. de C.V., es una Empresa consciente de la responsabilidad y confianza que usted deposita 
                    en nosotros, dedicada a proveer servicios de Ejecución en punto de venta: mercadeo, impulso, logística, colocación POP, 
                    auditorías, BTL 360, diseño y producción de materiales, reporteo en tiempo real, business intelligence, tecnología aplicada 
                    al punto de venta, logística de materiales y administración de inventarios y administración de personal, por esto nos 
                    encontramos comprometidos con el buen tratamiento de sus Datos Personales, en cumplimiento a la Ley Federal de Protección 
                    de Datos Personales en Posesión de Particulares, su Reglamento, así como los Lineamientos del Aviso de Privacidad 
                    publicados en el Diario Oficial de la Federación el 17 de enero de 2013, se hace de su conocimiento el presente 
                    Aviso de Privacidad.
                  </p>

                  <div style="padding-left: 14px;">
                    <div><span class="avisoTopic">I.&nbsp;&nbsp;&nbsp;RESPONSABLE</span></div>
                    <div style="padding-left: 16px;">
                      El Representante del Tratamiento de Datos Personales es Advertising and Promotion, S.A. de C.V., con domicilio en calle Augusto Rodín, número 135, Colonia Noche Buena, C.P. 03720, Alcaldía de Benito Juárez, Ciudad de México.
                    </div>
                  </div>

                  <div style="padding-left: 14px;margin-top: 20px;">
                    <div><span class="avisoTopic">II.&nbsp;&nbsp;USO DE LOS DATOS PERSONALES</span></div>
                    <div style="padding-left: 16px;">
                      Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:
                      Proveer del Servicio de Ejecución en punto de venta;
                      Generar una Base de Datos que facilite la localización de su solicitud de información o servicios mercadeo, impulso, logística, colocación POP, auditorías, BTL 360, diseño y producción de materiales, reporteo en tiempo real, business intelligence, tecnología aplicada al punto de venta, logística de materiales y administración de inventarios y administración de personal;
                      Mantener una relación con usuarios, clientes y cualquier interesado en los Servicios anteriores; y
                      Para control estadístico del uso de los Servicios de mercadeo.
                    </div>
                  </div>


                  <div style="padding-left: 14px;margin-top: 20px;">
                    <div><span class="avisoTopic">III.&nbsp;FINALIDADES</span></div>
                    <div style="padding-left: 16px;">
                      De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias, 
                      pero si útiles para el servicio solicitado, pues nos permiten brindarle una mejor atención:
                      <br>&nbsp;<br>
                      Evaluación de Calidad en la Atención y el Servicio;<br>
                      Difusión de Información relacionada con los Servicios;<br>
                      Mercadotecnia;<br>
                      De Publicidad; y<br>
                      Prospección comercial.<br>
                      Para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad, utilizaremos los siguientes datos personales:
                      <br>&nbsp;<br>
                      Nombre;<br>
                      Sexo;<br>
                      Correo electrónico;<br>
                      Registro Federal de Contribuyentes (RFC);<br>
                      Domicilio;<br>
                      Teléfono;<br>
                      Edad; y<br>
                      Fecha de nacimiento.
                    </div>
                  </div>

                  <div style="padding-left: 14px;margin-top: 20px;">
                    <div><span class="avisoTopic">IV.&nbsp;TRANSFERENCIA DE DATOS PERSONALES</span></div>
                    <div style="padding-left: 16px;">
                      Le informamos que sus datos personales podrán ser compartidos dentro o fuera del país con personas, empresas, organizaciones o autoridades miembros del grupo o distintas a nosotros, con el objetivo general de cumplir con las finalidades para las cuales ha proporcionado sus datos, y así apoyarnos en la realización de nuestra actividad profesional.
                      <br>&nbsp;<br>
                      Con fundamento en los artículos 36 y 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, los Datos Personales pueden ser transferidos lícitamente para las Finalidades establecidas en el presente Aviso de Privacidad.
                    </div>
                  </div>

                  <div style="padding-left: 14px;margin-top: 20px;">
                    <div><span class="avisoTopic">V.&nbsp;CONSENTIMIENTO PARA EL USO DE DATOS PERSONALES.</span></div>
                    <div style="padding-left: 16px;">
                      El tratamiento de los Datos Personales estará siempre sujeto a que el Titular proporcione su consentimiento ya sea de manera expresa, 
                      manifestando su voluntad de manera verbal, por medios electrónicos, ópticos, por cualquier otra tecnología o por signos inequívocos, 
                      o bien de manera tácita, entendiendo por conocimiento tácito cuando habiéndose puesto a disposición del Titular el Aviso de Privacidad, 
                      éste no manifieste oposición alguna.
                      <br>&nbsp;<br>
                      Para ambos consentimientos el Titular de los Datos Personales cuenta con un plazo de 5 días para manifestar su oposición al tratamiento de dichos 
                      Datos Personales, en caso de que no se manifieste oposición se entenderá que se ha manifestado su consentimiento.<br>&nbsp;
                    </div>
                    <div style="padding-left: 8px;">
                        <ol type="A">
                          <li>No será necesario el consentimiento para el tratamiento de los Datos Personales cuando:</li>
                          <li>Esté previsto en una Ley;</li>
                          <li>Los datos figuren en fuentes de acceso público;</li>
                          <li>Los Datos Personales se sometan a un procedimiento previo de disociación;</li>
                          <li>Tenga el propósito de cumplir obligaciones derivadas de una relación jurídica entre el Titular y el responsable;</li>
                          <li>Exista una situación de emergencia que potencialmente pueda dañar a un individuo en su persona o en sus bienes;</li>
                          <li>Sean indispensables para la atención médica, la prevención, diagnóstico, la prestación de asistencia sanitaria, tratamientos médicos o 
                            la gestión de servicios sanitarios, mientras el Titular no esté en condiciones de otorgar el consentimiento, 
                            en los términos que establece la Ley General de Salud y demás disposiciones jurídicas aplicables y 
                            que dicho tratamiento de datos se realice por una persona sujeta al secreto profesional u obligación 
                            equivalente; o
                          </li>
                          <li>Se dicte resolución de autoridad competente.</li>
                        </ol>
                    </div>
                  </div>

                  <div style="padding-left: 14px;margin-top: 20px;">

                    <div>
                      <span class="avisoTopic">VI.	EJERCICIO DE DERECHOS A.R.C.O.<br>“Derecho de Acceso, Rectificación, Cancelación y Oposición.”</span>
                    </div>
                    <div style="padding-left: 16px;">
                      Los Titulares de los Datos Personales que se encuentran en posesión de Advertising and Promotion, S.A. de C.V., en cualquier momento, podrá ejercer los derechos ARCO.
                      <br>&nbsp;<br>
                      Para poder hacer valer los derechos ARCO, es necesario que el Titular de los Datos Personales realice una solicitud con los requisitos que se detallan a continuación:
                      <br>&nbsp;
                    </div>
                    <div style="padding-left: 8px;">
                      <ol type="A"><!-- links agregados --> 
                        <li>El nombre y domicilio o correo electrónico del Titular para comunicarle la respuesta a su solicitud;</li>
                        <li>Los documentos que acrediten la identidad del Titular, o en su caso, la representación legal de quien actúe en su nombre;</li>
                        <li>La descripción clara y precisa de los Datos Personales respecto de los que el Titular busca ejercer alguno de los derechos antes mencionados;</li>
                        <li>Cualquier otro elemento o documento que facilite la localización de los Datos Personales del Titular;</li>
                        <li>Especificar claramente si la solicitud es de acceso, rectificación, cancelación u oposición;</li>
                        <li>El motivo de la solicitud; y (vii) las modificaciones a realizarse en caso de que la solicitud sea para la rectificación de Datos Personales.<br>
                          Esta solicitud deberá de hacerla llegar a Advertising and Promotion, S.A. de C.V. por cualquiera de los siguientes medios:<br>
                          Vía correo electrónico.<br>
                          Correo postal.<br>
                          Personalmente en las oficinas de Advertising and Promotion, S.A. de C.V.<br>
                          Por medio del sitio web <a href="http://www.ayp.com.mx/" target="_blank" rel="noreferrer noopener">www.ayp.com.mx</a><br>
                          El procedimiento que Advertising and Promotion, S.A. de C.V. adoptará una vez que haya recibido la solicitud consta en lo siguiente:
                          <br>&nbsp;<br>
                          Hará de su conocimiento en un plazo máximo de 20 (veinte) días naturales, contados a partir de la fecha en que haya recibido la solicitud, 
                          la determinación adoptada, y en caso de que la misma resulte procedente, se hará efectiva dentro de los 15 (quince) días naturales siguientes
                          a la fecha en que se comunique su procedencia.
                          <br>&nbsp;<br>
                          Se le informa que los plazos antes referidos Advertising and Promotion, S.A. de C.V. podrá ampliarlos cuando las 
                          particularidades del caso así lo ameriten. Lo anterior se le notificará al Titular por el mismo medio en que fue realizada la solicitud.
                          <br>&nbsp;<br>
                          Los datos de contacto del departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos A.R.C.O., son los siguientes:<br>
                          Nombre del departamento de datos personales: Comité de Protección de Datos Personales;<br>
                          Domicilio: calle Augusto Rodín, número 135, Col. Noche Buena, Alcaldía de Benito Juárez, C.P. 03720, Ciudad de México;<br>
                          Correo electrónico: <a href="mailto:datospersonales@ayp.mx">datospersonales@ayp.mx</a>;<br>
                          Número telefónico: 01 800 083 0990<br>
                        </li>
                      </ol>

                    </div>
                  </div>

                  <div style="padding-left: 14px;margin-top: 20px;">
                    <div>
                      <span class="avisoTopic">VII.	USO DE TECNOLOGÍAS DE RASTREO EN NUESTRO PORTAL DE INTERNET</span>
                    </div>
                    <div style="padding-left: 16px;">
                      Le informamos que, en nuestra página de internet www.ayp.com.mx, utilizamos cookies, web beacons u otras tecnologías, 
                      a través de las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un mejor servicio 
                      y experiencia al navegar en nuestra página. Los datos personales que recabamos a través de estas tecnologías, 
                      los utilizaremos para los siguientes fines:<br>
                      Formar electrónicamente los resultados de los estudios realizados<br>
                      Emisión de comprobantes Fiscales. Facturación.<br>
                      Solicitud de servicios, consultas o cotizaciones, referentes a los ser<br>
                      Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes: 
                      Nombre completo, nombre de usuario, número de expediente, correo electrónico, contraseñas de ingreso, fecha de nacimiento y RFC.<br>
                    </div>
                  </div>


                  <div style="padding-left: 14px;margin-top: 20px;">
                    <div>
                      <span class="avisoTopic">VIII.	RECOPILACION DE INFORMACION POR PARTE DE TERCEROS</span>
                    </div>
                    <div style="padding-left: 16px;">
                      El Portal www.ayp.com.mx contiene enlaces a otros sitios cuyas prácticas de información pueden ser diferentes a 
                      las del Portal. En caso de visitar otros sitios, es responsabilidad del Titular consultar las condiciones de 
                      privacidad de dichos sitios. Advertising and Promotion, S.A. de C.V. en ningún caso y bajo ninguna circunstancia 
                      será responsable por el Tratamiento que otros sitios distintos al Portal den a los Datos Personales del Usuario.
                    </div>
                  </div>

                  <div style="padding-left: 14px;margin-top: 20px;">
                    <div>
                      <span class="avisoTopic">IX.	ACTUALIZACION DEL AVISO</span>
                    </div>
                    <div style="padding-left: 16px;">
                      Advertising and Promotion, S.A. de C.V. se reserva el derecho a modificar la presente Política de Privacidad para adaptarla a 
                      novedades legislativas o jurisprudenciales, así como a prácticas de la industria. En dichos supuestos, Advertising and 
                      Promotion, S.A. de C.V. anunciará en el Portal los cambios introducidos con razonable antelación a su puesta en práctica.
                    </div>
                  </div>

                  <br>&nbsp;<br>Última Actualización: agosto 11, 2019

              </div>

            </div>
            <div class="buttonZone" style=" margin-top: 16px !important;">
              <q-btn
                unelevated
                :label="'Cerrar'"
                type="button" class="buttonSave_SE" color="app_mainBtn" 
                @click.stop="doCloseFinishDialog"
              />
            </div>

          </q-card-section>

        <!-- Loader de forma -->
        <!-- -->
        <q-inner-loading :showing="modalForm.processing">
          <q-spinner-orbit size="100px" color="indigo" />
        </q-inner-loading>
        
      </q-card>
    </q-dialog>
</template>

<script>
import logOut_Mixin from '../../../mixins/requestMix.js'
import myplatform_Mixin from '../../../mixins/platformMix.js'
import helperBase_Mixin from '../../../mixins/helperBase.js'

export default {
  name: 'modalAvisoPriv_form',
  props: {
    actionForm: { // verify, add, remove, mainchange
      type: String,
      required: true,
      default: 'show'
    },

  },
  components: {
    cxIcon: require('components/appIcons.vue').default
  },
  mixins: [
    logOut_Mixin,
    myplatform_Mixin,
    helperBase_Mixin
  ],
  data () {
    return {
      version: '1.0.1',
      // -----------------
      initFormFlag: false,
      processingData:false, 
      modalForm: {
        show: false,
        formErrors: 0,
        action: 'review', // // add,  edit , dupx
        stepForm: 0, // 0 : ingresar datos ,  1:codigo de verificacion  2: Terminado / cerrar 
        processing: false, 
      }
    }
  },
  computed: {
    show: {
      get: function () {
        return this.$parent.frm_avisoShow
      },
      set: function (value) {
        this.$parent.frm_avisoShow = value
      }
    },
    parentX () {
      return this.$parent
    }
  },
  watch: {
    initFormFlag: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$forceUpdate()
      }
    },
    'modalForm.show': function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        //this.modalForm.datesErrors.show = false 
      } else if (newVal !== oldVal && newVal === false) {
        // Se resetea la info del formulario 
      }
      this.$forceUpdate()
    },
    'modalForm.stepForm': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$forceUpdate()
      }
    },
    'modalForm.processing': function (newVal, oldVal) {
      this.$forceUpdate()
    }
  },
  methods: {
    doShow () {
      this.modalForm.show = true
    },
    doHide () {
      this.modalForm.show = false
    },
    setShownDialog () {
      this.$cannaDebug('-- verificaPhoneForm -- set Shown Dialog')
      this.frmDataOriginal = { // Aqui se almacenan los datos en edicion (originales para comparacion) //Se resetea por cualquier cosa
        email: ''
      }

      // ---------------------
      //this.modalForm.stepForm = this.modalidad // ETapa o eventos 
      this.modalForm.action = this.actionForm
      //this.modalForm.datesErrors.show = false // Forzar al que el mensaje de fechas no aparezca 
      setTimeout(() => {
        this.onReset()
        // acciones para el  formulario 
        this.initFormFlag = true
        this.$forceUpdate()
      }, 200)
    },
    doCloseFinishDialog () { 
      this.initFormFlag = false
      this.onReset()
      this.$cannaDebug('-- avisoPrivForm -- doClose Finish Dialog')
      this.$emit('doCloseDialog', { newState: false, finish:true })
      this.modalForm.show = false
    },
    doCloseDialog (checkProcessing) {
      if (checkProcessing !== undefined) {
        if (this.modalForm.processing === true) {
          return
        }
      }
      this.initFormFlag = false
      this.onReset()
      this.$emit('doCloseDialog', { newState: false })
      this.modalForm.show = false
    },
    onReset () {
      this.modalForm.formErrors = 0
      this.$forceUpdate()
    },
    // ---------------
  },
  beforeCreate () {
    this.$cannaDebug('-- avisoPrivForm -- beforeCreate')
    this.initFormFlag = false
  },
  created () {
    this.$cannaDebug('-- avisoPrivForm -- created')
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- avisoPrivForm --  beforeMount')
  },
  mounted () {
    this.$cannaDebug('-- avisoPrivForm -- mounted')
    //LLENAR los puestos Valido
    //this.initFormFlag = true
  },
  beforeUpdate () {
    // this.$cannaDebug('-- avisoPrivForm -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- avisoPrivForm -- updated')
  },
  activated () {
    // this.$cannaDebug('-- avisoPrivForm -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- avisoPrivForm -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- avisoPrivForm -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- avisoPrivForm -- destroyed')
  }
}
</script>
