<template>
  <div id="newAccount_step1_container" class="canna_account_pageZone">
    <div class="canna_account_zone">

      <!-- Pantalla 1 -->
      
      <div class="contenedor_account_a" v-show="(accountData.step===0)">
        <div class="main_title spacerRowTop48">
          Crear una cuenta 
        </div>
        <div class="main_subtitle spacerRowTop4">
          ¿Ya tienes una cuenta en Canna? <span class="main_actionText action_item" @click.stop="goLoginPage">Inicia sesión</span>
        </div>
        <div class="main_subtitle spacerRowTop48">
          Para comenzar, selecciona el país o <template v-if="($q.screen.lt.sm)"><br></template> la región en la que vives
        </div>
        <div class="country_container spacerRowTop24">
          <div class="btn_Country action_item">
            <div class="title noselect">México</div>
          </div>
          <div class="btn_Country btn_Country_disabled action_item" style="cursor:not-allowed;">
            <div class="title noselect">Centroamérica</div>
          </div>
        </div>
        <div class="bottom_account_zone spacerRowTop80" style="justify-content: flex-end !important;">
          <q-btn unelevated  label="Siguiente >" type="button" class="buttonSave btnAccountAction" color="app_mainBtn" 
            @click.stop="goToStepPage(1)"
          />
        </div>
      </div>

      <!-- Pantalla 2 -->
      <div class="contenedor_account_b" v-show="(accountData.step===1)">
        <div class="main_title spacerRowTop48">
          Crear una cuenta 
        </div>

        <div class="main_subtitle spacerRowTop24">
          Cuéntanos cómo te gusta que te llamen, tu dirección de correo <template v-if="($q.screen.gt.xs)"><br></template> 
          electrónico y elige una contraseña para tu cuenta Canna.
        </div>

        <div class="spacerRowTop26">

            <ValidationObserver ref="formstepA" tag="div" class="" v-slot="{ handleSubmit, valid, passed }">
              <q-form
                ref="myFormStepA"
                @submit.prevent="handleSubmit(onStepFormSubmitA)"
                @reset="onStepFormReset_a"
                class="canna_forma_zona"
                style="padding-bottom: 50px;"
              >
                <div class="canna_formZone">

                  <!-- Campo de alias -->
                  <ValidationProvider rules="required|cannaTextAlias|emojiFree|min:2|max:20" 
                    name="nombre" :immediate="false" v-slot="{ errors, invalid, validated  }"
                    tag="div" class="canna_forma_campo_container"
                  >
                    <div style="display: flex;justify-content: space-between;">
                      <div class="fieldLabel text-left"
                        :class="{
                          'fieldError':invalid && validated
                        }"
                      >¿Cómo te gusta que te llamen?</div>
                      <div class="requiredLabel text-right" >
                        Obligatorio
                      </div>
                    </div>
                    <div>
                      <q-input
                        filled
                        name="nombre"
                        v-model="accountData.data.alias"
                        hint="Si tienes algún apodo que te guste lo puedes escribir aquí."
                        :dense="true"
                        :label="(accountData.data.alias==='')?'Por ejemplo Anita, El flaco, etc.':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ? errores.alias : ''"
                        :disabled="processingData"
                      /><!--  errors[0].replace('nombre','El nombre').replace('El campo','') : ''" -->
                    </div>

                    
                  </ValidationProvider>

                  <!-- Campo de CURP --> <!-- dev-sprint17 nuevo flujo -->
                  <ValidationProvider rules="required|cannaCURP|emojiFree|min:18|max:18" name="curp" :immediate="false" v-slot="{ errors, invalid, validated  }">
                    <div class="canna_forma_campo_container" style="margin-top: 20px;">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >CURP</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        name="curp"
                        v-model="accountData.data.curp"
                        :dense="true"
                        mask="AAAA######AAAAAAX#"
                        fill-mask
                        :label="(accountData.data.curp==='')? '':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ? ( errors[0].indexOf('ya ha sido registrada')>=0 ? errors[0] : errores.curp) : ''"
                        :disabled="processingData"
                        @blur="pasoA_onBlurCampo('curp', $event)"
                        @input="val => { accountData.data.curp = val.toUpperCase() }"
                      ><!-- ENER 2022 se actualizo a "AAAA######AAAAAAX#"  en vez de  "AAAA######AAAAAA##" --> 
                      </q-input>
                      <div class="noselect" style="display: flex; justify-content: flex-end;margin-top: 14px;margin-bottom: 14px;">
                        <div class="labelLegend_query">
                          Consulta tu clave CURP <span class="main_actionText action_item" 
                          @click.stop="doShowRefDataLink(1)"
                        >aquí</span>
                        </div>
                      </div>
                    </div>
                  </ValidationProvider>

                  <!-- Campo de RFC --> <!-- dev-sprint17 nuevo flujo -->
                  <ValidationProvider rules="required|cannaRFC|emojiFree|min:15|max:15" name="rfc" :immediate="false" v-slot="{ errors, invalid, validated  }">
                    <div class="canna_forma_campo_container" style="margin-top: 16px;">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >RFC</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        name="rfc"
                        v-model.trim="accountData.data.rfc"
                        :dense="true"
                        :mask="'AAAA-######-XXX'"
                        fill-mask
                        :label="(accountData.data.rfc==='')? '':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ? ( errors[0].indexOf('ya ha sido registrada')>=0 ? errors[0] : errores.rfc) : ''"
                        :disabled="processingData"
                        @blur="pasoA_onBlurCampo('rfc', $event)"
                        @input="val => { accountData.data.rfc = val.toUpperCase() }"
                      >
                      </q-input>
                      <div class="noselect" style="display: flex; justify-content: flex-end;margin-top: 14px;margin-bottom: 14px;">
                        <div class="labelLegend_query">
                          Consulta tu clave RFC <span class="main_actionText action_item" @click.stop="doShowRefDataLink(2)">aquí</span>
                        </div>
                      </div>
                    </div>
                  </ValidationProvider>

                  <!-- Campo de NSS --> <!-- dev-sprint17 nuevo flujo -->
                  <ValidationProvider rules="required|cannaNSS|emojiFree|min:15|max:15" name="nss" :immediate="false" v-slot="{ errors, invalid, validated  }">
                    <div class="canna_forma_campo_container" style="margin-top: 16px;">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >NSS</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        name="nss"
                        v-model="accountData.data.nss"
                        :mask="'##-##-##-####-#'"
                        fill-mask
                        :dense="true"
                        :label="(accountData.data.nss==='')? '':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ? ( errors[0].indexOf('ya ha sido registrada')>=0 ? errors[0] : errores.nss) : ''"
                        :disabled="processingData"
                        @blur="pasoA_onBlurCampo('nss', $event)"
                      >
                      </q-input>
                      <div class="noselect" style="display: flex; justify-content: flex-end;margin-top: 14px;margin-bottom: 14px;">
                        <div class="labelLegend_query">
                          Consulta tu clave NSS <span class="main_actionText action_item" @click.stop="doShowRefDataLink(3)">aquí</span>
                        </div>
                      </div>
                    </div>
                  </ValidationProvider>

                  <!-- Campo de password -->
                  <ValidationProvider rules="required|cannaTextPassword|emojiFree|min:8|max:12" name="PassWord" :immediate="false" 
                    v-slot="{ errors, invalid, validated  }"
                    tag="div" class="canna_forma_campo_container" style="margin-top: 16px;"
                  >

                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >Contraseña</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <div>
                        <q-input
                          filled
                          :type="(accountData.inputPass===true) ? 'password' : 'text'"
                          name="password"
                          v-model="accountData.data.password"
                          hint=""
                          :dense="true"
                          :label="(accountData.data.password==='')?'Escribe tu contraseña':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ? errores.password : ''"
                          :disabled="processingData"
                        >
                          <template v-slot:append>
                            <div class="inputLabelAction noselect action_item" @click.stop="changeInpPass" 
                              v-if="accountData.data.password.length>=1"
                            >
                              {{(accountData.inputPass===true) ? 'MOSTRAR' : 'OCULTAR'}}  <!-- <q-icon name="r_star" /> -->
                            </div>
                          </template>
                        </q-input>
                      </div>

                  </ValidationProvider>

                  <div class="canna_forma_campo_container spacerRowTop17">
                    <div  style="font-size: 11px; line-height: 1; color: rgba(0, 0, 0, 0.54); text-align: left;" >
                      <div>
                        <div>
                            Tu contraseña debe tener entre 8 y 12 caracteres. Para que sea segura debes incluir en ella números, 
                            letras y signos de puntuación como: &#10095; - _ . ! * $ % &#38; / # ? ) = ¿ + ¡ ( &#10094;
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="canna_forma_campo_container spacerRowTop28">
                    <div style="display: flex;align-items: baseline;">
                      <div style="margin-left: -10px;">
                        <q-checkbox color="cannaMainColor" v-model="accountData.data.autorizo" />
                      </div>
                      <div>
                        <div class="labelLegend_a">
                          Autorizo en mi carácter de candidato y futuro empleado a la empresa, a utilizar
                            mis datos personales sensibles... Leer todo el 
                            <span class="main_actionText action_item" @click.stop="do_showAVP"> Aviso de privacidad </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

								<template v-if="(frmMainErrors.pasoA !== '')">
									<div class="canna_frmMainErrorZone spacerRowTop24" style="display: flex;flex-direction: column;align-items: center;justify-content: center;"
                    :style="(is_Mobile || $q.screen.lt.sm) ? 'min-width: 368px; max-width: 362px;' : ''"
                    :class="{
                      'canna_formZone': (is_Mobile || $q.screen.lt.sm) ? true : false
                    }"
                  >
										<div style="display: flex;margin-bottom: 12px;">
											<div style="margin-right:10px;">
												<img src="images/login_warning.png"/>
											</div>
											<div style="flex:1;" v-html="frmMainErrors.pasoA"></div>
										</div>
										<div v-if="(frmMainErrors.pasoA.indexOf('ya han sido utilizadas.')>=0)">
											<div>
												¡Oye! Probablemente ya habías creado una cuenta en Canna, por que no intentas <span class="main_actionText action_item" @click.stop="goLoginPage">Iniciar sesión.</span>
											</div>
										</div>

									</div>
								</template>

                <div class="bottom_account_zone spacerRowTop80">
                  <q-btn type="button" unelevated  label="< Atras"  class="buttonSave btnAccountAction" color="app_mainBtn"
                    @click.stop="goToStepPage(0)"
                  />
                  <q-btn type="submit" unelevated  label="Siguiente >"  class="buttonSave btnAccountAction" color="app_mainBtn"
                    :disable="((passed || valid) && accountData.data.autorizo===true  )? false : true"
                    @click.stop=""
                  />
                </div>

              </q-form>

            </ValidationObserver>

        </div>

      </div>

      <!-- Pantalla 3 -->
      <div class="contenedor_account_b" v-show="(accountData.step===2)" style="/*! padding: 0px 24px; */ width: calc(100% - 48px);">
        <div class="main_title spacerRowTop48" style="margin-top: 26px !important;">
          Bienvenido, <span class="resalte_text">{{accountData.data.alias}}</span>
        </div>

        <div class="main_subtitle spacerRowTop24">
            Para encontrar el puesto de trabajo ideal <template v-if="($q.screen.lt.sm)"><br></template>
            para ti, necesitamos algunos datos de tu <template v-if="($q.screen.lt.sm)"><br></template>información personal.
        </div>
        <template v-if="($q.screen.gt.xs)">
          <div class="main_subtitle">
            Puedes estar tranquilo, tu información está segura con nosotros no la compartimos ni vendemos con nadie.
          </div>
        </template> 

        <div class="spacerRowTop26">

            <ValidationObserver ref="formstepB" tag="div" class="" v-slot="{ handleSubmit, valid, passed }">
              <q-form
                ref="myFormStepB"
                @submit.prevent="handleSubmit(onStepFormSubmitB)"
                @reset="onStepFormReset_b"
                class="canna_forma_zona"
                style=""
              >
                <div class="canna_formZone">
                  <!-- Campo de nombre -->
                  <ValidationProvider rules="required|cannaTextNamesLetras|emojiFree|min:2|max:50" name="nombre" :immediate="false" v-slot="{ errors, invalid, validated  }">
                    <div class="canna_forma_campo_container">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >Nombre</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        name="nombre"
                        v-model="accountData.data.nombre"
                        hint="Escribe tu nombre o nombres"
                        :dense="true"
                        :label="(accountData.data.nombre==='')? '':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ? errores.nombre : ''"
                        :disabled="processingData"
                        @blur="pasoB_onBlurCampo('nombre', $event)"
                      />
                    </div>
                  </ValidationProvider>

                  <!-- Campo de apellido paterno -->
                  <ValidationProvider rules="required|cannaTextNamesLetras|emojiFree|min:2|max:50" name="paterno" :immediate="false" v-slot="{ errors, invalid, validated  }">
                    <div class="canna_forma_campo_container spacerRowTop24">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >Apellido paterno</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        name="paterno"
                        v-model="accountData.data.paterno"
                        hint=""
                        :dense="true"
                        :label="(accountData.data.paterno==='')?'':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ? errores.paterno : ''"
                        :disabled="processingData"
                        @blur="pasoB_onBlurCampo('paterno', $event)"
                      />
                    </div>
                  </ValidationProvider>

                  <!-- Campo de materno -->
                  <ValidationProvider 
                    :rules="{
                      required:(accountData.data.sin_materno===true) ? false : true, 
                      cannaTextNamesLetras:true, 
                      emojiFree:true, 
                      min:2,
                      max:50
                    }" name="materno" :immediate="false" v-slot="{ errors, invalid, validated  }">
                    <div class="canna_forma_campo_container spacerRowTop24">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >Apellido materno</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        name="materno"
                        v-model="accountData.data.materno"
                        hint=""
                        :disable="(accountData.data.sin_materno) ? true : false "
                        :dense="true"
                        :label="(accountData.data.materno==='')?'':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ? errores.materno : ''"
                        :disabled="processingData"
                        @blur="pasoB_onBlurCampo('materno', $event)"
                      >
                      </q-input>
                    </div>
                  </ValidationProvider>

                  <div class="canna_forma_campo_container">
                    <div style="display: flex;align-items: baseline;">
                      <div style="margin-left: -10px;">
                        <q-checkbox 
                          color="cannaMainColor"
                          v-model="accountData.data.sin_materno"
                          @input="(val, evt) => { pasoB_field_maternoInput(val, evt) }"
                        />
                      </div>
                      <div>
                        <div class="labelLegend_a">Legalmente no tengo apellido materno</div>
                      </div>
                    </div>
                  </div>

                  <!-- Campo de Telefono (lada y numero) --> <!-- lada por el momento es FIJO -->
                  <div class="spacerRowTop20" style="display: flex;justify-content: space-between;">
                    <div class="fieldLabel text-left">Número celular</div>
                    <div class="requiredLabel text-right" >
                      Obligatorio
                    </div>
                  </div>
                  <div style="display: flex; flex-direction: row; align-items: self-start;">

                    <div class="canna_forma_campo_container" style="max-width: 90px;">
                      <q-input
                        filled
                        disable
                        readonly
                        name="lada"
                        v-model="accountData.data.lada"
                        hint=""
                        :dense="true"
                        :label="(accountData.data.lada==='')? '':''"
                        :disabled="processingData"
                      />
                    </div>

                    <ValidationProvider 
                      rules="required|cannaPhones|emojiFree|min:10|max:10" name="telefono" 
                      tag="div" class="canna_forma_campo_container canna_forma_campo_celularA" style="margin-left:10px;flex: 1;"
                      :immediate="false" v-slot="{ errors, invalid, validated  }"
                    >
                        <q-input
                          filled
                          name="telefono"
                          v-model="accountData.data.telefono"
                          hint=""
                          :dense="true"
                          mask="(##) #### ####"
                          unmasked-value
                          :label="(accountData.data.telefono==='')? 'Número celular a 10 dígitos':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ? errores.phone : ''"
                          :disabled="processingData"
                          @blur="pasoB_onBlurCampo('telefono', $event)"
                        />

                    </ValidationProvider>
                  </div>

                  <!-- Campo de EMAIL -->
                  <ValidationProvider rules="required|email|emojiFree|max:255" 
                    name="Email" :immediate="false" v-slot="{ errors, invalid, validated  }"
                    tag="div" class="canna_forma_campo_container spacerRowTop24"
                  >
                    
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >Correo electrónico</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <div>
                        <q-input
                          filled
                          name="Email"
                          type="email"
                          v-model.trim="accountData.data.email"
                          hint=""
                          :dense="true"
                          :label="(accountData.data.email==='')?'ejemplo@email.com':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ? errores.email : ''"
                          :disabled="processingData"
                          @input="val => { accountData.data.email = ('' + val).trim()} "
                        />
                      </div>
                    
                  </ValidationProvider>

                </div>

								<template v-if="(frmMainErrors.pasoB !== '')">
									<div class="canna_frmMainErrorZone spacerRowTop24" 
                    style="display: flex;flex-direction: column;align-items: center;justify-content: center;"
                    :style="(is_Mobile || $q.screen.lt.sm) ? 'min-width: 368px; max-width: 362px;' : ''"
                  >
                    <div style="display: flex;margin-bottom: 12px; align-items: center;">
                      <div style="margin-right:10px;">
                        <img src="images/login_warning.png"/>
                      </div>
                      <div style="flex:1;" v-html="frmMainErrors.pasoB"></div>
                    </div>
									</div>
								</template>

                <div class="bottom_account_zone bottom_account_zoneAlt spacerRowTop32" style="margin: 24px auto;">
                  <q-btn unelevated  label="< Atras" type="button" class="buttonSave btnAccountAction" color="app_mainBtn"
                    @click.stop="goToStepPage(1)"
                  />
                  <q-btn unelevated  label="Finalizar" type="submit" class="buttonSave btnAccountAction" color="app_mainBtn"
                    :disable="((passed || valid) )? false : true"
                  />
                </div>

              </q-form>

            </ValidationObserver>

        </div>

      </div>


    </div>

    <q-inner-loading :showing="loading_Process" class=" app_innerLoader" style="top: -51px;z-index: 9000; position: fixed !important;">
      <q-spinner-orbit size="100px" color="indigo" />
    </q-inner-loading>

    <q-inner-loading :showing="zloadingData" class="" style="top: -51px;z-index: 9000; position: fixed !important;">
      <q-spinner-gears :size="(pageSize === 'sm' || pageSize === 'xs') ? '90px' : '120px' " color="cannaLoading" />
    </q-inner-loading>


    <!-- modal de privacidad  -->
    <frmAvisoPriv ref="frmAviso" :actionForm="'review'" v-on:doCloseDialog="doCloseavisoModal"></frmAvisoPriv>


  </div>
</template>

<script>
import { requestPack } from './../../boot/axios' // APP code 
import { canna_cookieMethods } from './../../boot/__cannav2_app'

import logOut_Mixin from './../../mixins/requestMix.js'
import myplatform_Mixin from './../../mixins/platformMix.js'
import helperBase_Mixin from './../../mixins/helperBase.js'

export default {
  name: 'canna_newaccount_screen',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin
  ],
  components:{
    frmAvisoPriv: require('components/forms/cuenta/avisoPivacidad.vue').default
  },
  data () {
    return {
      version: '1.0.1',
      debugEls: false,
      initFormFlag: true,
      zinitScreen: false,
      zloadingData: false,
      loading_Process:false,
      processingData: false,
      currentView: 'main', // 'main' ||  'loginview'
      profileRecord: null,
      frm_avisoShow: false,
      errores:{
        alias: 'Este dato debe contener entre 2 y 20 caracteres pueden ser letras y/o números. Por favor, revisa e inténtalo de nuevo.',
        email: 'El dato ingresado no tiene el formato de un correo electrónico. Por favor, revisa e inténtalo de nuevo.', 
        password: 'La contraseña no cumple con los requisitos mínimos. Por favor, revisa las indicaciones e inténtalo de nuevo.',
        nombre: 'Este dato debe contener entre 2 y 50 caracteres solo pueden ser letras. Por favor, revisa e inténtalo de nuevo.',
        paterno: 'Este dato debe contener entre 2 y 50 caracteres solo pueden ser letras. Por favor, revisa e inténtalo de nuevo.',
        materno: 'Este dato debe contener entre 2 y 50 caracteres solo pueden ser letras. Por favor, revisa e inténtalo de nuevo.',
        phone: 'El número celular ingresado no es válido. Por favor, revisa e inténtalo de nuevo. ',
        curp: 'La clave CURP ingresada no es valida. Por favor, revisa e inténtalo de nuevo.',
        rfc: 'La clave RFC ingresada no es valida. Por favor, revisa e inténtalo de nuevo.',
        nss: 'La clave NSS ingresada no es valida. Por favor, revisa e inténtalo de nuevo.'
      },
      frmMainErrors:{
        pasoA: '',
        pasoB: ''
      },
      frmMainRefs:{
        pasoA: [
          {fieldNameAPi: 'alias', fieldNameForm: 'nombre' },
					{fieldNameAPi: 'curp', fieldNameForm: 'curp' },
          {fieldNameAPi: 'rfc', fieldNameForm: 'rfc' },
          {fieldNameAPi: 'nss', fieldNameForm: 'nss' },
        ],
        pasoB: [
          {fieldNameAPi: 'paterno', fieldNameForm: 'paterno' },
          {fieldNameAPi: 'materno', fieldNameForm: 'materno' },
          {fieldNameAPi: 'nombre', fieldNameForm: 'nombre' },
          {fieldNameAPi: 'sin_materno', fieldNameForm: 'sin_materno', useGeneral:true},
          {fieldNameAPi: 'phone', fieldNameForm: 'telefono' },
					{fieldNameAPi: 'email', fieldNameForm: 'Email' }
        ]
      },
      accountData: {
        step: 0,
        inputPass: true,
        recordedPass:'',
        data:{
          region: 0 ,
          alias: '',
          email: '',
          password: '',
          autorizo: false,
          nombre:'',
          paterno:'',
          materno:'',
          sin_materno: false,
          lada:'MX (+52)',
          telefono:'',
          telefonoTipo:'',
          curp:'',
          rfc:'',
          nss:''
        }
      }
    }
  },
  computed: {
    mainErrors_a () {
      var countx = 0
      if (this.initFormFlag) {
        try {
          if (this.$refs.formstepA) {
            if (this.$refs.formstepA.errors) {
              var OBJKeys = Object.keys(this.$refs.formstepA.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formstepA.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    },
    mainErrors_b () {
      var countx = 0
      if (this.initFormFlag) {
        try {
          if (this.$refs.formstepB) {
            if (this.$refs.formstepB.errors) {
              var OBJKeys = Object.keys(this.$refs.formstepB.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formstepB.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    },
    s_loginData () {
      return this.$store.state.myapp.loginData
    }
  },
  watch: {
    currentView : function (newVal, oldVal) { 
      // WIP
    }
  },
  methods: {
    ...requestPack,
    ...canna_cookieMethods,
    test () {
      this.$cannaDebug('-- accountIndexScreen -- test')
    },
    goLoginPage () {
      var newRoute = '/'
      this.$router.push(newRoute)
    },
    do_showAVP () {
      this.$cannaDebug('-- accountIndexScreen -- aviso privacidad -- paso A')
      this.$forceUpdate()
      setTimeout(() => {
        this.$refs.frmAviso.doShow()
      }, 200)
    },
    doCloseavisoModal () {
      this.$refs.frmAviso.doHide()
      this.$forceUpdate()
    },
    changeInpPass (){
      this.accountData.inputPass = !this.accountData.inputPass 
      this.$forceUpdate()
    },
    doShowRefDataLink (numRef ){
      // numRef =  1:curp , 2:Rfc , 3:NSS
      var myDataLinks = [
        '', 
        'https://consultas.curp.gob.mx/CurpSP/inicio2_2.jsp',
        'https://www.sat.gob.mx/tramites/operacion/28753/obten-tu-rfc-con-la-clave-unica-de-registro-de-poblacion-curp',
        'http://www.imss.gob.mx/tramites/imss02008' // https://serviciosdigitales.imss.gob.mx
      ]
      var myurlUse = myDataLinks[numRef]
      if (myurlUse==='' || myurlUse===undefined){ 
        return false 
      } else { 
        window.open('' + myurlUse)
      }
    },

    goToStepPage ( numpage ) {
      if (numpage === 0){  // Alias , email password
        this.accountData.step = 0
      } else if (numpage === 1){  // Alias , password, curp, rfc, nss 
        if (this.accountData.step===0 || this.accountData.step===2){
          this.accountData.step = 1
        }
      } else if (numpage === 2){  // nombre , paterno, materno , email 

      }
      this.$forceUpdate() 
    },
    onStepFormSubmitA: async function () { // Para el paso 1 
      this.$cannaDebug('-- accountIndexScreen -- form submit paso A')
      var test = 11
      if (this.mainErrors_a > 0) {
        return false
      }
      var myProfileData = null
			var myData = { 
				curp: this.accountData.data.curp,
				rfc: this.accountData.data.rfc , 
				nss: this.accountData.data.nss 
			}
			this.loading_Process = true 
 			await this._waitRequestProc(100)

			var mxModel = this.getModelRequest('preRegistro')
      var reqRes = { action: [], login: [], profile: [], profileEdit:[] }
      var reqErr = { action: 0, login: 0, profile: 0, profileEdit:0 }


      var validConfig = this.createAxiosConfig ('get', mxModel, myData, false, null)
      await this.doRequestByPromise(this.$axios, 'newvalidate_account', reqRes.action, validConfig).then((resx) => {
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })

      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        this.ex_handlerErrorFormRequest (reqRes.action[0], reqErr.action, this.$refs.formstepA, 'formstepA',  this.frmMainRefs.pasoA , 'pasoA')
        var responseErr = reqRes.action[0].data.data
        if (Array.isArray(responseErr)){
            var countCRN = 0 
            responseErr.forEach(item => { 
              if (Array.isArray(item)===true){ //checar objeto 
                  if (item[0].message.indexOf('ingresada ya ha sido registrada')>=0) {
                    countCRN+=1
                  }
              }
            })
            if (countCRN>0){
              this.frmMainErrors.pasoA = 'Las claves CURP, RFC y/o NSS ya han sido utilizadas.'
            }
        }
        this.loading_Process = false
        return false 
      }
      await this._waitRequestProc(200)

      //Si todo esta bien 
      this.accountData.step = 2
      this.loading_Process = false
      this.$forceUpdate()
      return true 

      /*

            var myData = { 
              alias:'' + this.accountData.data.alias,
              email: '' + this.accountData.data.email,
              aviso_privacidad: 1,
              password: '' + this.accountData.data.password
            }
            // ------------------------
            this.loading_Process = true 
            this.$forceUpdate()
            await this._waitRequestProc(100)
            var mcModel = this.getModelRequest('userReg')
            var mloginModel = this.getModelRequest('_userlogin')
            var mpModel = this.getModelRequest('userProfile')

            var reqRes = { action: [], login: [], profile: [], profileEdit:[] }
            var reqErr = { action: 0, login: 0, profile: 0, profileEdit:0 }

            // Checamos si ya hay login y ya esta previamente creado el registro...! 
            
            var testRecord = (this.profileRecord === null) ? false : (typeof this.profileRecord === 'object' && this.profileRecord.constructor === Object ) ? true : false 
            var logObj = this.getLoginData 
            var testLogin = (logObj.logIn===true && logObj.user !== null && logObj.token !== null ) ? true : false 

            if (testRecord===true && testLogin===true) {  // Ya esta previamente el registro 

              var myEditData = {}
              var doChangePassOrEmail = false

              if (this.accountData.data.alias !== this.profileRecord.alias ) {
                myEditData.alias = '' + this.accountData.data.alias 
              }
              if (this.accountData.data.email !== this.profileRecord.email ) {
                myEditData.email = '' + this.accountData.data.email 
                doChangePassOrEmail = true
              }
              if (this.accountData.data.password !== this.accountData.recordedPass ){ 
                myEditData.password = '' + this.accountData.data.password
                doChangePassOrEmail = true
              }

              if (Object.keys(myEditData).length<=0){  // Si no hay cambios 
                this.accountData.step = 2
                this.loading_Process = false
                this.$forceUpdate()
                return true 
              }

              var myProfileEditConfig = this.createAxiosConfig ('patch', mpModel, { useData:true, data: myEditData } , true, null)
              await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profileEdit, myProfileEditConfig).then((resx) => {
                // console.log ('Action -- Carga de Clientes -- GET ejecutado')
                var tmpresponseData = reqRes.profileEdit[reqRes.profileEdit.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.profileEdit += 1
                }
              })

              if (reqErr.profileEdit > 0) { // Si hay error de server, session o algo redireccionamos..!
                test = 44
                this.ex_handlerErrorFormRequest (reqRes.profileEdit[0], reqErr.action, this.$refs.formstepA, 'formstepA',  this.frmMainRefs.pasoA , 'pasoA')
                this.loading_Process = false
                this.$forceUpdate()
                return false 
              }

              // Actualizo OK
              var needCKupdate = false
              if (doChangePassOrEmail === true){
                needCKupdate = true
                await this._waitRequestProc(1000) // Dejamos q se actualize en el server bien 
                // ------------------
                // hacemos llamada para obtener el token 
                this.accountData.recordedPass = this.accountData.data.password
                var myLogConfig = this.createAxiosConfig ('post', mloginModel, { 
                  useData: true ,
                  data: {
                    grant_type: 'password',
                    username: '' + this.accountData.data.email,
                    password: '' + this.accountData.data.password
                  } 
                }, false, null)
                
                await this.doRequestByPromise(this.$axios, 'userlogin', reqRes.login, myLogConfig).then((resx) => {
                  // console.log ('Action -- Carga de Clientes -- GET ejecutado')
                  var tmpresponseData = reqRes.login[reqRes.login.length - 1]
                  if (tmpresponseData.type !== 'success') {
                    reqErr.login += 1
                  }
                })
                if (reqErr.login > 0) { // Si hay error de server, session o algo redireccionamos..!
                  test = 55
                  this.loading_Process = false
                  return false
                }
                await this._waitRequestProc(100)
                var loginData = reqRes.login[0].data.data
                var myToken = loginData.access_token
                this.$store.commit('myapp/setloginData', { token: myToken }) //setloginData
                this.$store.commit('myapp/setAppUserData', { access_token: myToken })
                await this._waitRequestProc(100)
                // ------------------
              }

              // Hasta aqui estamos bien y obtenemos los datos de profile 
              var myPEditConfig = this.createAxiosConfig ('get', mpModel, { }, true, null) //test: 11
              await this.doRequestByPromise(this.$apiV2, 'userProfileData', reqRes.profile, myPEditConfig).then((resx) => {
                // console.log ('Action -- Carga de Clientes -- GET ejecutado')
                var tmpresponseData = reqRes.profile[reqRes.profile.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.profile += 1
                }
              })

              if (reqErr.profile > 0) { // Si hay error de server, session o algo redireccionamos..!
                test = 66
                this.loading_Process = false
                return false
              }
              var profileData = reqRes.profile[0].data.data
              this.profileRecord = JSON.parse(JSON.stringify(profileData))

              // ------------------
              if (needCKupdate===true) { // Finally we update cookie
                var dataObject = { logIn:true, user: profileData.id, token: myToken}
                var testCks = this.cannack_setCookieData (this.$q.cookies, dataObject)
                this.$cannaDebug('-- accountIndexScreen -- ckupdate -- ' + testCks)
                test = 77
              }
              // ------------------
              //Si todo esta bien finalmente 
              this.accountData.step = 2
              this.loading_Process = false
              this.$forceUpdate()
              return true 
            }
            //-------------------
            //-------------------
            //-------------------
            var myRegConfig = this.createAxiosConfig ('post', mcModel, { 
              useData: true ,
              data: myData 
            }, false, null)

            await this.doRequestByPromise(this.$axios, 'newaccount', reqRes.action, myRegConfig).then((resx) => {
              // console.log ('Action -- Carga de Clientes -- GET ejecutado')
              var tmpresponseData = reqRes.action[reqRes.action.length - 1]
              if (tmpresponseData.type !== 'success') {
                reqErr.action += 1
              }
            })

            if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
              test = 44
              this.ex_handlerErrorFormRequest (reqRes.action[0], reqErr.action, this.$refs.formstepA, 'formstepA',  this.frmMainRefs.pasoA , 'pasoA')
              this.loading_Process = false
              return false 
            }
            await this._waitRequestProc(200)
            this.$forceUpdate()
            // ------------------
            myProfileData = reqRes.action[0].data.data
            // ------------------
            // hacemos llamada para obtener el token 
            this.accountData.recordedPass = this.accountData.data.password
            var myLogConfig = this.createAxiosConfig ('post', mloginModel, { 
              useData: true ,
              data: {
                grant_type: 'password',
                username: '' + this.accountData.data.email,
                password: '' + this.accountData.data.password
              } 
            }, false, null)
            
            await this.doRequestByPromise(this.$axios, 'userlogin', reqRes.login, myLogConfig).then((resx) => {
              // console.log ('Action -- Carga de Clientes -- GET ejecutado')
              var tmpresponseData = reqRes.login[reqRes.login.length - 1]
              if (tmpresponseData.type !== 'success') {
                reqErr.login += 1
              }
            })
            if (reqErr.login > 0) { // Si hay error de server, session o algo redireccionamos..!
              test = 55
              this.loading_Process = false
              return false
            }
            await this._waitRequestProc(100)
            var loginData = reqRes.login[0].data.data
            var myToken = loginData.access_token
            this.$store.commit('myapp/setloginData', {logIn:true, user: myProfileData.id, token: myToken }) //setloginData  //logIn: false, user: null, token: null
            this.$store.commit('myapp/setAppUserData', { access_token: myToken })
            this.$forceUpdate()
            await this._waitRequestProc(200)
            // ------------------
            // ------------------
            // Hasta aqui estamos bien y obtenemos los datos de profile 
            var myProfileConfig = this.createAxiosConfig ('get', mpModel, { }, true, null) //test: 11 
            await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profile, myProfileConfig).then((resx) => {
              // console.log ('Action -- Carga de Clientes -- GET ejecutado')
              var tmpresponseData = reqRes.profile[reqRes.profile.length - 1]
              if (tmpresponseData.type !== 'success') {
                reqErr.profile += 1
              }
            })
            if (reqErr.profile > 0) { // Si hay error de server, session o algo redireccionamos..!
              test = 66
              this.loading_Process = false
              return false
            }
            var profileData = reqRes.profile[0].data.data
            this.profileRecord = JSON.parse(JSON.stringify(profileData))
            // ------------------
            // ------------------
            // Finally we update cookie
            var dataObject = { 
              logIn:true,
              user: profileData.id,
              token: myToken
            }

            var testCks = this.cannack_setCookieData ( this.$q.cookies, dataObject)
            this.$cannaDebug('-- accountIndexScreen -- ckupdate -- ' + testCks)
            test = 77

      */
    },

    onStepFormReset_a () { // Para el paso 1 
      this.accountData.data.alias = ''
      this.accountData.data.curp = ''
      this.accountData.data.rfc = ''
      this.accountData.data.nss = ''
      this.accountData.data.password = ''
      this.accountData.data.autorizo = false
      if (this.$refs.myFormStepA) {
        this.$refs.myFormStepA.reset()
      }
      this.$forceUpdate()
    },
    pasoA_onBlurCampo: function( fieldName , event ){ 
      this.$cannaDebug('-- accountIndexScreen -- pasoC onBlur Campo')
      if (this.frmMainErrors.pasoA !== ''){ 
        this.frmMainErrors.pasoA = ''
      }
      if (fieldName === 'rfc' ||fieldName === 'curp') { 
        if (fieldName === 'rfc') {
          if (this.accountData.data.rfc!==null && this.accountData.data.rfc!==''){
            this.accountData.data.rfc = this.accountData.data.rfc.toUpperCase()
          }
        }
        if (fieldName === 'curp') {
          if (this.accountData.data.curp!==null && this.accountData.data.curp!==''){
            this.accountData.data.curp = this.accountData.data.curp.toUpperCase()
          }
        }
      }
      this.$forceUpdate()
    },
    pasoB_field_maternoInput (value, event) {
      if (value === true) {
        this.accountData.data.materno = ''
      } 
      this.pasoB_onBlurCampo('materno', event )
      this.$forceUpdate()
    },
    pasoB_onBlurCampo: function( fieldName , event ){ 
      this.$cannaDebug('-- accountIndexScreen -- pasoB onBlur Campo')
      if (this.frmMainErrors.pasoB!=''){ 
        this.frmMainErrors.pasoB = ''
        this.$forceUpdate()
      }
      if (fieldName === 'telefono'){
        this.pasoB_validatePhone()
      }
    },
    pasoB_validatePhone: async function(){ 
      var test = 11
      var reqRes = { action: [] }
      var reqErr = { action: 0 }
      var myPhone = ('+52' + this.accountData.data.telefono).trim()
      if (myPhone.length!==13){
        return true 
      }

      // if (test === 11) {  // temporal override 
      //   return true 
      // }

      // preCelular: ['register','pre-cel'], //api/v2/pre-cel?numero= 
		  // preRegistro: ['register','pre-registro'], // ${secure}://${server}/index.php/api/v2/pre-registro?curp=SAGC930520HDFLRS01&rfc=SAGC-930520-FQ8&nss=82-11-93-3548-5

      var mpModel = this.getModelRequest('preCelular') // var mpModel = this.getModelRequest('telefonoCheck')
      var myTelCfg = this.createAxiosConfig ('get', mpModel, { numero: myPhone }, false, null)
      await this.doRequestByPromise(this.$axios, 'celular-pre-registro', reqRes.action, myTelCfg).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // no deberia haber errores nunca, solo es consulta 
        test = 44
        this.ex_handlerErrorFormRequest (reqRes.action[0], reqErr.action, this.$refs.formstepB, 'formstepB',  this.frmMainRefs.pasoB , 'pasoB')
        this.loading_Process = false
        this.$forceUpdate()
        return false 
      }
      var telData = reqRes.action[0].data.data
      if (telData.length<=0){
        // Mostrar error q no existe el telefono 
        var myObjectNoPhone = { 
          telefono: 'El número no existe o no corresponde a un número celular. Por favor, revisa e inténtalo de nuevo.'
        }
        this.frmMainErrors.pasoB = 'El número no existe o no corresponde a un número celular. Por favor, revisa e inténtalo de nuevo.'
        this.$refs.formstepB.setErrors(myObjectNoPhone);
        this.$forceUpdate()
        return false 
      } else {
        // checar si es movil o FIJO
        var myREcord = telData[0]
        this.accountData.data.telefonoTipo = myREcord.tipo_red
        if (myREcord.tipo_red!=='MOVIL'){
          var myObjectNoPhone = { 
            telefono: 'El número ingresado no corresponde a un número celular. Por favor, revisa e inténtalo de nuevo.'
          }
          this.frmMainErrors.pasoB = 'El número ingresado no corresponde a un número celular. Por favor, revisa e inténtalo de nuevo.'
          this.$refs.formstepB.setErrors(myObjectNoPhone);
          this.$forceUpdate()
          return false 
        }
      }
      return true
    }, 
    onStepFormSubmitB: async function () {
      this.$cannaDebug('-- accountIndexScreen -- form submit paso B')
      var test = 11
      if (this.mainErrors_b > 0) {
        return false
      }
      //Se hace una segunda validacion por si las dudas en caso de que aprieten muy rapido el boton 

      var testTelphone = null 
      await this.pasoB_validatePhone().then(responseTel => {
        testTelphone = responseTel
      })
      if (testTelphone===false){ 
        return false 
      }
      // ------------------------
      var myData = { 
        alias:'' + this.accountData.data.alias,
        email: '' + this.accountData.data.email,
        aviso_privacidad: 1,
        password: '' + this.accountData.data.password,
        curp:'' + this.accountData.data.curp,
        rfc: '' + this.accountData.data.rfc,
        nss: this.accountData.data.nss,
        nombre:'' + this.accountData.data.nombre,
        paterno: '' + this.accountData.data.paterno,
        phone: '+52' + this.accountData.data.telefono
      }

      if (this.accountData.data.sin_materno ===true ) {
        myData.sin_materno = 1
        myData.materno = '' 
      } else {
        myData.sin_materno = 0
        myData.materno = this.accountData.data.materno
      }

      // ------------------------
      this.loading_Process = true 
      this.$forceUpdate()
      await this._waitRequestProc(100)
      // ------------------------
      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }

      var muModel = this.getModelRequest('userReg') //Registro de usuario 
      var mloginModel = this.getModelRequest('_userlogin')

      var mcModel = this.getModelRequest('profilePersonales')
      var mpModel = this.getModelRequest('userProfile')
      //-------------------
      var myRegConfig = this.createAxiosConfig ('post', muModel, { 
        useData: true ,
        data: myData 
      }, false, null)

      await this.doRequestByPromise(this.$axios, 'newaccount', reqRes.action, myRegConfig).then((resx) => {
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })

      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 44
        this.ex_handlerErrorFormRequest (reqRes.action[0], reqErr.action, this.$refs.formstepB, 'myFormStepB',  this.frmMainRefs.pasoB , 'pasoB')
        this.loading_Process = false
        return false 
      }
      await this._waitRequestProc(200)
      // ------------------
      var myProfileData = reqRes.action[0].data.data
      // ------------------
      // hacemos llamada para obtener el token 
      this.accountData.recordedPass = this.accountData.data.password

      var myLogConfig = this.createAxiosConfig ('post', mloginModel, { 
        useData: true ,
        data: {
          grant_type: 'password',
          username: '' + this.accountData.data.email,
          password: '' + this.accountData.data.password
        } 
      }, false, null)
      
      await this.doRequestByPromise(this.$axios, 'userlogin', reqRes.login, myLogConfig).then((resx) => {
        var tmpresponseData = reqRes.login[reqRes.login.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.login += 1
        }
      })

      if (reqErr.login > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.loading_Process = false
        return false
      }
      await this._waitRequestProc(100)

      var loginData = reqRes.login[0].data.data
      var myToken = loginData.access_token
      this.$store.commit('myapp/setloginData', {logIn:true, user: myProfileData.id, token: myToken }) //setloginData  //logIn: false, user: null, token: null
      this.$store.commit('myapp/setAppUserData', { access_token: myToken })
      this.$forceUpdate()
      await this._waitRequestProc(200)
      // ------------------
      // Hasta aqui estamos bien y obtenemos los datos de profile
      var myProfileConfig = this.createAxiosConfig ('get', mpModel, { }, true, null) //test: 11
      await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profile, myProfileConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.profile[reqRes.profile.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.profile += 1
        }
      })

      if (reqErr.profile > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.loading_Process = false
        return false 
      }

      var profileData = reqRes.profile[0].data.data
      this.profileRecord = JSON.parse(JSON.stringify(profileData))

      //Si todo esta bien 
      // Actualizamos el VUEX STORE con el perfil y nos vamos al DASHboard...!
      this.$store.commit('myapp/setMainState',{ appUserProfile: profileData })
      await requestPack._waitRequestProc(200)
      // alert ("nos vamos al DASHBOARD....!")
      // ------------------
      // update cookie DATA
      var dataLoginObject = { 
        logIn:true,
        user: profileData.id,
        token: myToken
      }
      var testCks = this.cannack_createCookie (this.$q.cookies, 'CannaV2Public', dataLoginObject, true) 
      this.$cannaApiService.$cannaAxios.defaults.headers.common.Authorization = `Bearer ${loginData.access_token}`
      this.$cannaDebug('-- loginscreen -- ck created && updated -- ' + testCks)
      test = 77
      // ------------------
      // Generamos / actualizamos la cookie si existe  o no 
      var newRuta = '/dashboard'
      this.loading_Process = false
      this.$forceUpdate()

      this.$router.push(newRuta)
      return true 

      /*


            var myProfilePasoB = this.createAxiosConfig ('patch', mcModel, { 
              useData:false,
              data: myData
            }, true, null)

            await this.doRequestByPromise(this.$apiV2, 'profilepersonales', reqRes.action, myProfilePasoB).then((resx) => {
              // console.log ('Action -- Carga de Clientes -- GET ejecutado')
              var tmpresponseData = reqRes.action[reqRes.action.length - 1]
              if (tmpresponseData.type !== 'success') {
                reqErr.action += 1
              }
            })

            if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
              test = 44
              this.ex_handlerErrorFormRequest (reqRes.action[0], reqErr.action, this.$refs.formstepB, 'formstepB',  this.frmMainRefs.pasoB , 'pasoB')
              this.loading_Process = false
              this.$forceUpdate()
              return false 
            }

            await this._waitRequestProc(100)

            // Hasta aqui estamos bien y obtenemos los datos de profile
            var myProfileConfig = this.createAxiosConfig ('get', mpModel, { test: 11 }, true, null)
            await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profile, myProfileConfig).then((resx) => {
              // console.log ('Action -- Carga de Clientes -- GET ejecutado')
              var tmpresponseData = reqRes.profile[reqRes.profile.length - 1]
              if (tmpresponseData.type !== 'success') {
                reqErr.profile += 1
              }
            })

            if (reqErr.profile > 0) { // Si hay error de server, session o algo redireccionamos..!
              test = 55
              this.loading_Process = false
              return false 
            }


            var profileData = reqRes.profile[0].data.data
            this.profileRecord = JSON.parse(JSON.stringify(profileData))

            //Si todo esta bien 
            this.accountData.step = 3
            this.loading_Process = false
            this.$forceUpdate()

      */

    },
    onStepFormReset_b () {
      this.accountData.data.nombre = ''
      this.accountData.data.paterno = ''
      this.accountData.data.materno = ''
      this.accountData.data.sin_materno = false
      this.accountData.data.lada = 'MX (+52)'
      this.accountData.data.telefono = ''
      this.accountData.data.email = ''
      if (this.$refs.myFormStepB) {
        this.$refs.myFormStepB.reset()
      }
      this.$forceUpdate()
    },

    onStepFormSubmitC: async function () {
      this.$cannaDebug('-- accountIndexScreen -- form submit paso C')
      var test = 11
      if (this.mainErrors_c > 0) {
        return false
      }
      var myData = { 
        curp:'' + this.accountData.data.curp,
        rfc: '' + this.accountData.data.rfc,
        nss: this.accountData.data.nss
      }
      // ------------------------
      this.loading_Process = true 
      this.$forceUpdate()
      await this._waitRequestProc(100)
      // ------------------------

      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }

      var mcModel = this.getModelRequest('profileLegales')
      var mpModel = this.getModelRequest('userProfile')

      var myProfilePasoC = this.createAxiosConfig ('patch', mcModel, { 
        useData:false,
        data: myData
      }, true, null)

      await this.doRequestByPromise(this.$apiV2, 'profilepersonales', reqRes.action, myProfilePasoC).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })

      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 44
        this.ex_handlerErrorFormRequest (reqRes.action[0], reqErr.action, this.$refs.formstepC, 'formstepC',  this.frmMainRefs.pasoB , 'pasoC')
        this.loading_Process = false
        this.$forceUpdate()
        return false 
      }
      

      // Hasta aqui estamos bien y obtenemos los datos de profile
      var myProfileConfig = this.createAxiosConfig ('get', mpModel, { }, true, null) //test: 11
      await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profile, myProfileConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.profile[reqRes.profile.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.profile += 1
        }
      })

      if (reqErr.profile > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.loading_Process = false
        return false 
      }

      var profileData = reqRes.profile[0].data.data
      this.profileRecord = JSON.parse(JSON.stringify(profileData))

      //Si todo esta bien 
      // Actualizamos el VUEX STORE con el perfil y nos vamos al DASHboard...!
      this.$store.commit('myapp/setMainState',{ appUserProfile: profileData })
      await requestPack._waitRequestProc(200)

      // alert ("nos vamos al DASHBOARD....!")

      // Generamos / actualizamos la cookie si existe  o no 
      var newRuta = '/dashboard'
      this.loading_Process = false
      this.$forceUpdate()

      this.$router.push(newRuta)
      return true 
    },

    async initLogData () {
      // Inicializa los datos si el usuario ya esta logeado pero no ha terminado la cuenta 
      var profileData = JSON.parse(JSON.stringify(this.getProfileData))
      this.profileRecord = profileData

      var testData = Object.keys(profileData)

      if (testData.length>=1){ //Si hay informacion en el profile 
          // Pantalla 1
          this.accountData.data.alias = (profileData.alias!=='') ? profileData.alias : ''
          this.accountData.data.email = (profileData.email!=='') ? profileData.email : ''
          this.accountData.data.password =  '' // no hay forma
          this.accountData.data.autorizo = true
          this.frmMainErrors.pasoA = 'Se recomienda cambiar ó confirmar la contraseña usada por motivo de creación de cuenta incompleta'
          // Pantalla 2
          this.accountData.data.nombre = (profileData.nombre!=='') ? profileData.nombre : ''
          this.accountData.data.paterno = (profileData.paterno!=='') ? profileData.paterno : ''
          this.accountData.data.materno = (profileData.materno!=='') ? profileData.materno : ''
          this.accountData.data.sin_materno = (profileData.sin_materno!==0) ? true : false
          this.accountData.data.telefono = (profileData.phone!=='') ? profileData.phone.replace('+52','') : ''

          // pantalla 3 (no se usa...!)
          if (profileData.status===10){
            if (this.$refs.formstepA){
              this.$refs.formstepA.validate({ silent: true })
            }
            this.accountData.step = 2
          } else if (profileData.status===11){
            if (this.$refs.formstepA){ 
              this.$refs.formstepA.validate({ silent: true })
            }
            if (this.$refs.formstepB){ 
              this.$refs.formstepB.validate({ silent: true })
            }
            this.accountData.step = 3
          }

      }
      return true
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- accountIndexScreen -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- accountIndexScreen -- created')
    this.zloadingData = true 
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- accountIndexScreen --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- accountIndexScreen -- mounted')
    // var xd = JSON.parse(JSON.stringify(this.s_loginData))

    // if (xd.logIn===true && xd.user!==null & xd.token!==null) { 
    //   var myResp = null
    //   await this._waitRequestProc(200)
    //   await this.initLogData().then( respx => { 
    //     myResp = respx 
    //     this.$cannaDebug('-- accountIndexScreen -- init Log Data finished')
    //   })

    //   await this._waitRequestProc(200)
    // }
    this.zloadingData = false
    this.$forceUpdate()
  },
  beforeUpdate () {
    // this.$cannaDebug('-- accountIndexScreen -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- accountIndexScreen -- updated')
  },
  activated () {
    this.$cannaDebug('-- accountIndexScreen -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- accountIndexScreen -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- accountIndexScreen -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- accountIndexScreen -- destroyed')
  }
}
</script>